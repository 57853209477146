body{
  overflow-x: hidden;
}

.columns{
  margin: 0 !important;
}

a{
  font-family: Nunito, sans-serif;
  text-decoration: underline;
  color: #9999FF;
}

h1, h2,h3,h4,h5{
  font-family: Nunito, sans-serif;
  font-weight: bold;
}

.input,
.textarea,
.custom-select div.header,
.calendar-input div.header{
  clip-path: polygon(0% 0%, 6% 3%, 12% 5%, 19% 3%, 27% 3%, 34% 2%, 40% 1%, 46% 3%, 51% 3%, 59% 0%, 64% 5%, 69% 5%, 75% 5%, 81% 1%, 88% 5%, 94% 1%, 100% 0%, 100% 100%, 94% 100%, 89% 95%, 83% 99%, 77% 95%, 71% 100%, 66% 100%, 62% 97%, 57% 97%, 53% 100%, 47% 100%, 40% 100%, 34% 95%, 29% 100%, 22% 98%, 15% 92%, 9% 97%, 0% 99%);
  -moz-clip-path: polygon(0% 0%, 6% 3%, 12% 5%, 19% 3%, 27% 3%, 34% 2%, 40% 1%, 46% 3%, 51% 3%, 59% 0%, 64% 5%, 69% 5%, 75% 5%, 81% 1%, 88% 5%, 94% 1%, 100% 0%, 100% 100%, 94% 100%, 89% 95%, 83% 99%, 77% 95%, 71% 100%, 66% 100%, 62% 97%, 57% 97%, 53% 100%, 47% 100%, 40% 100%, 34% 95%, 29% 100%, 22% 98%, 15% 92%, 9% 97%, 0% 99%);
  border-radius: 15px !important;
  background-color: #EEEEEE;
  text-align: left;
  border-color: transparent;
}

input.input{
  background-color: #EEEEEE;
}

.input-style2{
  background-color: red;
}

.select-style2 div.header,
.calendar-input-style-2 div.header,
input.input-style3,
textarea.textarea-style3{
  background-color: white !important;
}

.container-content-pathed2{
  position: relative;

}

.container-content-pathed2 .bg-pathed{
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  text-align: center;
  border-radius: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.container-content-pathed2 .content-pathed{
  position: relative;
  z-index: 999;
}


.container-content-pathed{
  position: relative;
}

.container-content-pathed .bg-pathed > img{
  float: left;
  width: 100%;
  line-height: normal;
  height: auto;
}


.container-content-pathed .content-pathed{
  position: relative;
  z-index: 999;
  background-color: white;
  float: left;
  width: 100%;
  margin-top: -2px;
}

.react-calendar button{
  font-family: Montserrat, sans-serif;
  font-size: 11px !important;
}

.react-calendar{
  border-radius: 0 0 6px 6px !important;
  border: none !important;
  background-color: #EEEEEE !important;
  box-shadow: 0px 5px 20px -11px #000000;
}

.img-rocket{
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-name: floating-rocket;
  animation-duration: 600ms;
}

@keyframes floating-rocket {
  from {
    transform: translateY(-10px) scale(-1, 1);
  }

  to {
    transform: translateY(-0px) scale(-1, 1);
  }
}


.ReactModal__Content .content{
  padding: 40px 20px;
  position: relative;
  box-sizing: border-box;
  z-index: 99;
}

.icon-follow{
  transform: scale(1.4);
}

.ReactModal__Content{
  inset: 0px !important;
}

.slick-slider .slick-track, .slick-slider .slick-list{
  overflow: visible;
  margin: 0;
}


.slick-prev, .slick-next,
.slick-prev, .slick-prev{
  z-index: 999;
  width: 34px !important;
  height: 34px !important;
  padding: 8px !important;
  box-sizing: border-box;
  border-radius: 16px;
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.slick-slider:hover .slick-next,
.slick-slider:hover .slick-prev{
  opacity: 1;
}


.slick-prev, .slick-next::before,
.slick-prev, .slick-prev::before{
  display: none;
}

.slick-prev, .slick-next svg,
.slick-prev, .slick-prev svg{
  width: 100%;
  height: 100%;
  color: white;
}

.button-unstyled{
  margin:0;
  padding:0;
  border:0;
  background: transparent;
}

.is-tall {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.input:active, .input:focus, .is-active.input, .is-active.textarea, .is-focused.input, .is-focused.textarea, .select select.is-active, .select select.is-focused, .select select:active, .select select:focus, .textarea:active, .textarea:focus{
  box-shadow: none !important;
}

.ReactModal__Overlay{
  z-index: 99999 !important;
}

div.section-taller-arte h2{
  color: #CC6666;
}

div.section-taller-arte .slick-prev,
div.section-taller-arte .slick-next,
div.section-taller-arte .button,
div.section-taller-arte .button:focus,
div.section-taller-arte .button:visited,
div.section-taller-arte .button:active{
  background: #CC6666 !important;
}

div.section-taller-arte .button:hover{
  background-color: #CB5858;
}


div.section-taller-literatura h2{
  color: #336699;
}

div.section-taller-literatura .slick-prev,
div.section-taller-literatura .slick-next,
div.section-taller-literatura .button,
div.section-taller-literatura .button:focus,
div.section-taller-literatura .button:visited,
div.section-taller-literatura .button:active{
  background: #336699 !important;
}


div.section-taller-literatura .button:hover{
  background-color: #23486d;
}


div.section-taller-ciencia h2{
  color: #CCCC66;
}

div.section-taller-ciencia .slick-prev,
div.section-taller-ciencia .slick-next,
div.section-taller-ciencia .button,
div.section-taller-ciencia .button:focus,
div.section-taller-ciencia .button:visited,
div.section-taller-ciencia .button:active{
  background: #CCCC66 !important;
}


div.section-taller-ciencia .button:hover{
  background-color: #a2a251;
}


div.section-taller-construcciones h2{
  color: #FF9900;
}

div.section-taller-construcciones .slick-prev,
div.section-taller-construcciones .slick-next,
div.section-taller-construcciones .button,
div.section-taller-construcciones .button:focus,
div.section-taller-construcciones .button:visited,
div.section-taller-construcciones .button:active{
  background: #FF9900 !important;
}


div.section-taller-construcciones .button:hover{
  background-color: #e18701;
}

div.section-taller-historias h2{
  color: #336699;
}

div.section-taller-historias .slick-prev,
div.section-taller-historias .slick-next,
div.section-taller-historias .button,
div.section-taller-historias .button:focus,
div.section-taller-historias .button:visited,
div.section-taller-historias .button:active{
  background: #336699 !important;
}

div.section-taller-historias .button:hover{
  background-color: #1d3956;
}


div.section-pack-4 h2{
  color: #9999FF;
}

div.section-pack-4 .slick-prev,
div.section-pack-4 .slick-next,
div.section-pack-4 .button,
div.section-pack-4 .button:focus,
div.section-pack-4 .button:visited,
div.section-pack-4 .button:active{
  background: #9999FF !important;
}

div.section-pack-4 .button:hover{
  background-color: #8D8DE3;
}


@media screen and (max-width:769px) {
  .slick-slider .slick-prev{
    left: 0;
  }

  .slick-slider .slick-next{
    right: 0;
  }

  .slick-slider .slick-arrow.slick-disabled{
    opacity: 0.5;
  }

  .input, .textarea, .custom-select div.header, .calendar-input div.header{
    clip-path: none;
    -webkit-clip-path: none;
  }

  .ReactModal__Content{
    padding: 0 !important;
  }
}
