.container-alert{
    max-width: 350px;
    text-align: center;
    margin-top: 20px;
}

.container-alert .container-content-pathed{
    padding: 8px 10px;
    box-sizing: border-box;
}
.container-alert .close{
    z-index: 999;
    position: absolute;
    right: -22px;
    top: -14px;
    cursor: pointer;
    width: 25px;
}

.container-alert .content-pathed{
    border-radius: 10px;
    display: flex;
}

.container-alert .content-pathed img{
    width: 30px;
}


.container-alert.container-alert-style-error,
.container-alert.container-alert-style-success {
    max-width: 500px;
}

.container-alert.container-alert-style-error .container-content-pathed{
    background-color: #CC6666;
    border: 2px solid #b05959;
    border-radius: 10px;
    color: #2C3E50;
    font-family: Nunito, sans-serif;
    float: left;
    color: white;
}

.container-alert.container-alert-style-success .container-content-pathed{
    background-color: #DEEFDF;
    border: 2px solid #CBDBCE;
    border-radius: 10px;
    color: #2C3E50;
    font-family: Nunito, sans-serif;
    float: left;
}

.container-alert.container-alert-style-error .content-pathed,
.container-alert.container-alert-style-success .content-pathed{
    background-color: transparent;
}

.container-alert.container-alert-style-error .container-content-pathed .bg-pathed,
.container-alert.container-alert-style-success .container-content-pathed .bg-pathed{
    background-color: transparent;
}

.container-alert.container-alert-style-error .container-content-pathed .content-pathed span,
.container-alert.container-alert-style-success .container-content-pathed .content-pathed span{
    font-weight: 800;
}

@media screen and (max-width:769px) {
    .container-alert.container-alert-style-error,
    .container-alert.container-alert-style-success {
        max-width: 330px;
    }
}
